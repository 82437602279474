* {
  box-sizing: border-box;
}

#home {
  height: 100%;
  width: 100%;
  margin: 0 0 0 0;
}

body {
  font-family: "Outfit";
  margin: 0;
  padding: 0;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

.welcome-text {
  font-size: 2.3vh;
  font-family: "Outfit";
}

.login-logo {
  margin: auto;
  display: block;
}

.alert {
  font-size: xx-large;
  width: auto;
  height: auto;
}

.login-container {
  display: flex;
  max-width: 100%;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 62vh;
  /* Make the container take the full viewport height */
}

.login {
  text-align: center;
  padding: 20px;
  border-radius: 5px;
}

.inputBox {
  width: 100%;
  margin: 10px 0;
}

.align-left {
  text-align: left;
}

.reset-password-text {
  font-size: 2vh;
  font-family: "Outfit";
}

.nav-bar {
  z-index: 10000;
  /* top */
  background-color: rgb(255, 255, 255);
  /* Change the background color to blue */
  width: 100% !important;
  right: 0%;
  top: 0%;
  height: 10vh;
  overflow: hidden;
  min-height: 40px;
  max-height: 10%;
  box-shadow: 0px 10px 6px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-between;
  position: sticky;
  /* Align content to the edges */
  transition: transform 0.2s ease-in-out;
}

@media (max-width: 500px) {
  .nav-bar {
    padding-left: 2%;
    padding-right: 2%;
  }
}

.nav-bar-img {
  display: flex;
  width: auto;
  height: 100%;
  max-height: 100%;
  max-width: auto;
  margin-left: 50%;
  min-height: 20%;
  min-width: auto;
}



.nav-bar-button-container {
  display: flex;
  direction: rtl;
  flex-direction: row-reverse;
  /* Reverse the order of buttons */
  align-items: center;
  /* Center align the buttons vertically */
  padding-right: 5%;
  max-width: 90%;
  width: auto;
}

.nav-bar-button {
  width: auto;
}

.nav-bar-text {
  font-size: 1.2vw;
  text-decoration: underline;
  text-decoration-color: #51b1e0;
  text-underline-offset: 15%;
  text-decoration-thickness: 3px;
  font-family: "Outfit";
}

.nav-bar-button:not(:last-child) {
  margin-right: 3vw;
  /* Adjust the spacing as needed */
}

.log-in-button {
  color: white;
  font-size: 0.7vw;
  text-transform: none;
  transform: translate(0, 8%);
}

.nav-bar-button {
  color: #51b1e0;
}

.settings-button {
  width: 100%;
  height: 100%;
  transform: scale(2.5);
}

@media screen and (max-width: 768px) and (max-height: 500px) {
  .nav-bar {
    height: 20vh;
  }

  .nav-bar-text {
    font-size: 2vw;
  }

  .log-in-button {
    font-size: 1vw;
    width: auto;
  }

  .nav-bar-button-img {
    max-width: 21%;
    margin-left: 4%;
  }

  .nav-bar-img {
    display: flex;
    width: auto;
    max-height: 80%;
    margin-left: 15%;
  }
}

@media (max-width: 500px) {
  .nav-bar {
    height: 10vh;
  }

  .nav-bar-text {
    font-size: 1vb;
  }

  .log-in-button {
    font-size: 1.5vw;
    width: auto;
  }

  .nav-bar-img {
    width: 8vb;
    height: 6vb;
    display: none;
  }

  .nav-bar-button-container {
    display: flex;
    direction: rtl;
    align-items: center;
    /* Center align the buttons vertically */
    padding-right: 1%;
    padding-left: 1%;
    max-width: 100%;
    width: 100%;
  }

  .nav-bar-button-img {
    display: none !important;
  }

  .nav-bar-text {
    font-size: 1.4vb;
  }
}

.about {
  z-index: -5;
  /* middle */
  right: 0%;
  top: 0%;
  min-height: 100vh;
  max-height: none;
  height: auto;
  min-width: 100%;
  background-color: white;
}

.about-photo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vb;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.about-photo {
  max-width: 100%;
  width: 100%;
  height: auto;
  opacity: 0.6;
  overflow: hidden;
  width: auto;
  min-width: 100%;
  filter: blur(2px);
}

@media (max-width: 500px) {
  .about-photo {
    max-width: 300%;
    min-width: 200%;
    min-height: 30vh;
    height: auto;
    opacity: 0.6;
    overflow: hidden;
  }
}

.image-text {
  width: 100%;
  position: absolute;
  top: 53%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Center the text perfectly */
  text-align: center;
  font-size: 20vw;
  text-shadow: 4px 4px 4px rgba(49, 47, 47, 0.885);
  opacity: 1 !important;
  font-family: "Great Vibes";
}

body {
  max-width: 100%;
}

.bottom-part {
  max-width: 100%;
  height: auto;
  display: flex;
  padding-top: 2%;
  font-family: "Outfit";
}

.what-is {
  flex: 0 0 60%;
  font-size: 2vw;
  text-wrap: wrap;
  padding-left: 5%;
  padding-right: 5%;
  text-align: left;
}

.what-is-text {
  margin-top: 1%;
  text-align: left;
}

.what-is-text.two{
  margin-bottom: 10%;
}

.what-is-title {
  font-size: 2.5vw;
}

.RMS-text {
  font-size: 2vw;
}

.RMS-container {
  margin-top: 3%;
  margin-bottom: 5%;
  flex: 0 0 60%;
  text-wrap: wrap;
  padding-left: 5%;
  padding-right: 5%;
  text-align: left;
  width: 60%;
}

.RMS-img-container {
  display: flex;
  justify-content: right;
  float: right;
  height: 32vw;
  width: auto;
  padding-bottom: 10%;
  margin-right: 8%;
  margin-top: 3.5%;
}

.RMS-img {
  max-width: 100%;
  max-height: 32vw;
  box-shadow: 6px 6px 16px rgb(0, 0, 0, 0.7);
}

@media (max-width: 500px) {
  .RMS-img-container {
    margin-top: 8%;
    height: 55vw;
    margin-right: 0%;
    width: 70%;
    justify-content: center;
    float: center;
  }
  .RMS-img {
    max-height: 55vw;
  }
}

@media (max-width: 768px) {
  .image-text {
    top: 50%;
    /* Center vertically */
    font-size: 20vw;
  }
}

@media (max-width: 500px) {
  .image-text {
    font-size: 35vw;
  }

  .what-is-title {
    font-size: 5vw;
  }

  .what-is {
    flex: 0 0 100%;
    font-size: 4vw;
  }
}

.guy-img {
  padding-top: 2%;
  padding-left: 3%;
  padding-right: 7% !important;
  display: flex;
  max-height: 50vh;
  /* Maximum height for each item */
  height: auto;
  flex: 0 0 40%;
}

.image-guy {
  max-width: 100%;
  max-height: 50vh;
  /* Maximum height for each item */
  height: auto;
  box-shadow: 6px 6px 16px rgb(0, 0, 0, 0.7);
}

@media (max-width: 500px) {
  .what-is {
    padding-top: 4%;
    text-align: center;
    font-size: 4vw;
  }

  .what-is-text {
    text-align: center;
  }

  .bottom-part {
    flex-direction: column;
    /* Display items in a column layout */
    align-items: center;
    /* Center items horizontally */
    text-align: center;
    /* Center text within each item */
  }

  .guy-img {
    padding-top: 6%;
    padding-left: 5%;
    padding-right: 5% !important;
    height: auto;
    display: flex;
  }

  .image-guy {
    max-width: 100%;
    max-height: 50vh;
    /* Maximum height for each item */
    height: auto;
  }
}

.bottom-part.two {
  margin-top: 0;
  margin-bottom: 1%;
}

.bottom-part-images {
  margin-left: 5%;
  max-width: 40%;
  margin-bottom: 5%;
}

.image-ribbon {
  max-width: 100%;
}

.ribbon-img {
  display: inline-block;
  filter: drop-shadow(1px 1px 16px rgb(0, 0, 0, 0.4));
}

.image-cctdi {
  max-width: 100%;
}

.what-is.two {
  padding-top: 5%;
  align-items: center;
  justify-content: center;
}

@media (max-width: 500px) {
  .bottom-part.two {
    margin-top: 4%;
  }

  .bottom-part-images {
    order: 2;
  }

  .what-is.two {
    order: 1;
  }

  .bottom-part-images {
    padding-top: 3%;
    margin-left: 5%;
    max-width: 70%;
    margin-bottom: 5%;
  }

  .image-ribbon {
    max-width: 75%;
  }
}

.footer {
  padding: 2%;
  /* Add padding to the footer content */
  width: 100%;
  /* Make the footer span the entire width of the viewport */
  min-width: 100%;
  max-height: 15vh;
  height: 15vh;
  bottom: 0;
  background-color: rgb(30, 30, 30);
  display: flex;
  justify-content: space-between;
}

.logo-footer {
  height: 100%;
  max-width: 20%;
  max-height: 100%;
}

@media (max-width: 500px){
  .logo-footer {
    margin-top: 6%;
    height: 100%;
    max-width: 15%;
    max-height: 75%;
  }
}

.footer-img {
  margin-left: 1%;
  max-height: 100%;
}

@media (max-width: 500px) {
  .footer {
    max-width: 25%;
    padding: 3%;
    max-height: 13vh;
    height: 13vh;
    padding-bottom: 7%;
  }

  .footer-img {
    margin-left: 4%;
  }
}

.content-footer {
  max-width: 80%;
  margin-top: 2%;
  text-align: right;
  font-family: "Outfit";
}

.footer-text {
  color: white;
  font-family: "Outfit";
  font-size: 1vw;
  text-align: right;
}

@media (max-width: 500px) {
  .footer-text {
    margin-top: 12%;
    font-size: 2.5vw;
  }

  .content-footer {
    max-width: 80%;
    margin-top: 4%;
    text-align: right;
    font-family: "Outfit";
  }
}

@media (min-width: 501px) {
  .insta {
    transform: translate(10%, -10%);
  }
}

.volunteer-text {
  max-width: 90%;
  width: 90%;
  margin-left: 8%;
}

.volunteer-text .top-tutors {
  margin-top: 5%;
  font-size: 2.5vw;
}

.top-tutor-text {
  margin-bottom: 1.5%;
}

.volunteer-text .text {
  font-size: 1.5vw;
  text-wrap: wrap;
}

.become-A-Tutor {
  margin-top: 1%;
  margin-bottom: 3%;
  background-color: #ffffff;
}

@media (max-width: 500px) {
  .volunteer-text {
    margin-top: 10%;
  }

  .volunteer-text .top-tutors {
    font-size: 7vw;
    margin-right: 5%;
  }

  .volunteer-text .text {
    font-size: 4.5vw;
    text-wrap: wrap;
  }
}

@media (min-width: 500px) {
  .tutorswithimage {
    display: grid;
    grid-template-columns: 8fr 3fr;
  }

  .top-tutor-text {
    font-weight: bold;
  }
}

@media (max-width: 500px) {
  .schools-image {
    display: none;
  }
}

.faq-container {
  margin: 2%;
  margin-right: 6%;
  margin-left: 6%;
}

.faq-heading {
  text-align: center;
  font-size: 8vb;
  font-weight: bold;
  padding-top: 2%;
  padding-bottom: 2%;
}

.faq-question {
  font-size: 4vb;
  font-weight: bold;
  padding-top: 1%;
  padding-bottom: 1%;
}

.faq-answer {
  font-size: 3vb;
  padding-bottom: 2%;
}

@media (max-width: 500px) {
  .faq-heading {
    font-size: 12vw;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .faq-question {
    font-size: 3vb;
    font-weight: bold;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .faq-answer {
    padding-bottom: 5%;
    font-size: 2.5vb;
    padding-left: 3%;
    padding-right: 3%;
  }
}

.tutoring {
  margin: 5%;
  margin-top: 3%;
}

.reqTutoring {
  font-size: 4vw;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3%;
}

.reqButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auto-container {
  flex: 0.5;
}

.messageText {
  margin-top: 0.5%;
  font-size: 1vw;
  text-align: left;
  margin-left: 25.25%;
}

@media (max-width: 500px) {
  .messageText {
    margin-left: 3%;
    font-size: 3vw;
  }
}

.courses-auto {
  width: 70%;
  margin-left: 15%;
}

.courses-button {
  right: 15%;
  width: 20vw;
  height: 100%;
  min-width: 20vw;
  min-height: 100%;
}

.course-button-text {
  font-size: 0.75vw;
}

@media (max-width: 1000px) {
  .courses-button {
    right: 15%;
    width: 25vw;
    height: 100%;
    min-width: 25vw;
    min-height: 100%;
  }

  .course-button-text {
    font-size: 1vw;
  }
}

@media (max-width: 500px) {
  .courses-auto {
    width: 100%;
    margin-left: -7.5%;
  }

  .auto-container {
    flex: 0.8;
  }

  .course-button-text {
    font-size: 2vw;
    font-weight: bold;
  }

  .courses-button {
    right: 0%;
    width: 35vw;
    height: 100%;
    min-width: 35vw;
    min-height: 100%;
  }
}

.courses-text {
  font-size: 3vw;
}

.courseDescription {
  margin-top: 3%;
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 1%;
}

.class {
  margin-top: 2%;
  margin-bottom: 2%;
}

.classtitle {
  font-size: 2vw;
  font-weight: bold;
}

.prerequisites {
  font-size: 1.5vw;
  font-weight: 350;
  margin-bottom: 2%;
}

.subject {
  border-left: 0.2vw solid;
  padding-left: 2%;
  margin-left: 2%;
}

.subjectSect {
  font-size: 2.5vw;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 10%;
}

.description {
  font-size: 1.5vw;
  padding-top: 0.5%;
  padding-bottom: 1%;
}

@media (max-width: 500px) {
  .courseDescription {
    font-size: 7vw;
  }

  .reqTutoring {
    margin-top: 6%;
    margin-bottom: 4%;
    font-size: 8vw;
  }

  .classtitle {
    font-size: 5vw;
  }

  .prerequisites {
    font-size: 3vw;
  }

  .description {
    font-size: 3vw;
  }

  .subjectSect {
    font-size: 6vw;
  }
}

.group-photo {
  max-width: 60%;
  width: 60%;
  height: auto;
  overflow: hidden;
  width: auto;
  min-width: 60%;
}

.groupPhoto-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vb;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.directoryPg {
  margin: 5%;
}

.groupPhoto-text {
  margin-top: 3%;
  font-size: 3vb;
  font-weight: bold;
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
}

.OurTutors {
  margin-top: 6%;
  margin-bottom: 5%;
  font-size: 4vw;
  font-weight: bold;
  text-align: center;
}

.TheTeam {
  text-align: center;
  font-size: 5vw;
  font-weight: bold;
  margin-bottom: 3%;
  display: none;
}

.Pricing {
  margin-top: 4%;
  font-size: 4vw;
  font-weight: bold;
  margin-bottom: 2%;
}

.priceDesc {
  font-size: 2vw;
  margin-bottom: 1%;
}

.payment {
  font-size: 1.5vw;
  margin-bottom: 1%;
}

@media (max-width: 500px) {
  .priceDesc {
    font-size: 4vw;
  }
  .payment {
    font-size: 3vw;
  }
}

.tutors-container {
  width: 100%;
  margin-right: 3%;
  margin-left: 3%;
  margin-bottom: 12%;
}

.tutors-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 4%;
}

.tutor-image-container {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  min-height: auto;
  min-width: 75%;
  max-width: 100%;
  max-height: auto;
  height: auto;
  overflow: hidden;
  display: inline-block;
}

.tutordirectory {
  padding-left: 2%;
}

.tutor-photos {
  max-width: 80%;
  width: 80%;
  height: auto;
  overflow: hidden;
  width: auto;
  min-width: 60%;
  min-height: auto;
  box-shadow: 3px 3px 3px rgb(0, 0, 0, 0.8);
  text-align: center;
  align-items: center;
}

.tutor-name-container {
  text-align: center;
}

.tutor-name {
  display: block;
  font-size: 1.5vw;
  text-align: left;
}

.ownerText {
  font-size: 0.9vw;
  font-weight: 1000;
  display: inline-block;
}

@media (max-width: 500px) {
  .OurTutors {
    font-size: 12vw;
  }

  .tutors-grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4%;
  }

  .groupPhoto-text {
    font-size: 3vw;
    margin-bottom: 10%;
  }

  .tutor-name {
    font-size: 2.5vw;
  }

  .ownerText {
    font-size: 1.5vw;
  }
}

.tutoringstatus {
  text-align: center;
  font-size: 2vw;
  padding-bottom: 1.5%;
  margin-top: -1%;
}

@media (max-width: 500px) {
  .tutoringstatus {
    font-size: 3vw;
  }
}

.logout {
  padding-top: 30%;
}

@keyframes fadeIn {
  from {
      opacity: .5;
  }
  to {
      opacity: 1;
  }
}

.fadein {
  animation: fadeIn 0.5s ease-out forwards;
}

.homeInsta{
border-top: 0.4vw solid;
padding-bottom: 2%;
}

.our-insta{
  margin-top: 3%;
  margin-bottom: 3%;
  font-size: 8vb;
  font-weight: bold;
  text-align: center;
}